/* --------------------------------------------- */
/*  Status Label Styling                         */
/* --------------------------------------------- */

.Status-Label {
    padding: 5px 20px;
    border-radius: 20px;
    color: white;
    font-weight: 500;
    margin: 0px;
    min-width: 150px;
    text-align: center;
    font-size: 14px;
}