/* ------------------------------------------------------ */
/*  Login Container (Unauthenticated State)               */
/* ------------------------------------------------------ */

.LoginPage-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 2%;
}

.Login-Container {
    display: flex;
    flex-direction: column;
    gap: 35px 0px;
    max-width: 600px;
    /* border: 1px solid lightgrey; */
    border-radius: 5px;
    padding: 4% 5%;
    text-align: center;
}

.Login-Container h4 {
    font-weight: medium;
}

.Login-Container button {
    max-width: 600px;
    min-width: 300px;
}

.Login-Form {
    text-align: left;
}

.InviteUser-Container {
    text-align: left;
}

.InviteUser-Form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.InviteUser-Input {
    width: max-content;
    padding: 5px 10px;

}

.InviteUser-Error {
    color: red;
    font-weight: bold;
}

/* ------------------------------------------------------ */
/*  Login Page Styling (Unauthenticated State)            */
/* ------------------------------------------------------ */


.LoginHandler-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 2%;
}

.Login-Graphic {
    max-width: 250px;
    max-height: 250px;
}

.Login-Detail {
    display: flex;
    flex-direction: column;
    gap: 20px 0px;
    margin: 25px;
    font-size: var(--body);
}

.Login-Button {
    text-align: left;
}

/* ===============================================

    Failed and Error pages

    Generic grid that supports 2 columns and 3 rows, layout is below:
        'grumpy-cat title'
        'grumpy-cat solution'
        'grumpy-cat button'
        'error error' 

=============================================== */


.LoginHandler-Failed-Container {
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-template-areas:
        'LoginHandler-Failed-Img'
        'LoginHandler-Failed-Error';
    gap: 30px;
    max-width: 35%;
    position: absolute;
    top: 20%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}


.LoginHandler-Failed-Img {
    grid-area: LoginHandler-Failed-Img;
    display: flex;
    justify-content: center;
}

.LoginHandler-Failed-Title {
    grid-area: LoginHandler-Failed-Title;
    text-align: justify;
    align-self: center;
    padding: 0px;
    margin: 0px;
}

.LoginHandler-Failed-Error {
    grid-area: LoginHandler-Failed-Error;
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    align-items: center;
}

.LoginHandler-Failed-Error p {
    margin: 0px;
}


/* ===============================================
    Pending pages containers
        'pending'
        'pending-signin'
=============================================== */

.LoginHandler-Pending {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 10% 15%;
    align-items: center;
}

/* ------------------------------------------------------------ */
/** CSS Media Queries (Mobile)                                 **/
/* ------------------------------------------------------------ */

@media (max-width: 500px) {

    .Login-Graphic {
        display: none;
    }

}