/* ------------------------------------------------------------ */
/** App Styling                                                **/
 /* ------------------------------------------------------------ */

/* Ensures if you change the box-sizing of an element, everything inside the element will inherit the box-sizing of that element */
*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-Container{
    display: grid;
    grid-template-rows: 90px calc(100vh - 90px);
    grid-template-areas:
        'App-Container-Nav'
        'App-Container-Body';
    min-width: 900px;
}

.App-Container-Nav{
    grid-area: App-Container-Nav;
    width: 100%;
}

.App-Container-Body{
    grid-area: App-Container-Body;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}