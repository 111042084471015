/* Form Layout & Styling */
/* Benno-TODO ~ Chat with Nowshin if this should be added to index.css */
.CreateProject-Form {
    padding-top: 20px;
    display: grid;
    row-gap: 20px;
}

.CreateProject-Form-Row {
    border-top: 1px #D8D8D8 solid;
    margin-top: 10px;
    padding: 5px;
}

.CreateProject-Form-Row label {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.CreateProject-Form-Row select {
    margin-top: 10px;
}

.CreateProject-Form-Row input {
    padding: 20px;
    color: black;
}

.CreateProject-Form-Row textarea {
    padding: 20px;
    color: black;
}

.CreateProject-Form-Row select { 
    color: black;
}

.CreateProject-Form-Radio {
    display: flex;
    align-items: center;
}

/* Project Owners Classes */
.CreateProject-Form-ProjectOwners {
    display: grid;
    grid-template-columns: 500px;
    align-items: center;
    row-gap: 10px;
}

.CreateProject-Form-New {
    background-color: rgba(132, 193, 212, 0.19);
    padding: 20px;
    border-radius: 5px;
}

/* ================================================ */
/*   Upload NDA                                     */
/* ================================================ */

/* Onload Container */
.Upload-NDA-Onload-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0px 15px;
}

.Upload-NDA-Onload-Container input {
    width: 100%;
    height: 40px;
}

/* 'Choose File' (inside input [file] */

.Upload-NDA-Onload-Container input::-webkit-file-upload-button {
    content: 'Choose File';
    margin: 0px 15px 0px 0px;
    height: 40px;
    border: none;
    border-right: 1px #D8D8D8 solid;
    color: #545454;
    padding: 5px 15px;
    cursor: pointer;
}

/* Error Message */
.Upload-NDA-Error-Message {
    color: red;
    text-align: left;
}

/* Pending Container */
.Upload-NDA-Pending-Container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    gap: 0px 15px;
}

/* Success Container */
.Upload-NDA-Success-Container {
    display: flex;
    flex-direction: row;
    gap: 0px 15px;
}

.Upload-NDA-Success-Container a {
    font-size: var(--body);
    width: 100%;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    background-color: white;
    padding: 10px 20px;
    align-items: center;
    
}

/* Error Fatal */
.Upload-NDA-Error-Container {
    display: flex;
    flex-direction: column;
    gap: 10px 0px;
}

/* ---------------------------------------------- */
/*  New User Form                                 */
/* ---------------------------------------------- */

.New-User-Form {
    display: grid;
    gap: 5px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "New-User-Header"
        "New-User-Email"
        "New-User-Name"
        "New-User-Buttons";
    background-color: rgba(132, 193, 212, 0.19);
    padding: 25px;
    border-radius: 5px;
    margin: 0px;
    height: 300px;
}

.New-User-Form p {
    font-size: 13px;
    color: #454040;
    margin: 6px 0px;
}

.New-User-Form input {
    font-size: 13px;
    width: 100%;
}

/* Header */

.New-User-Header {
    grid-area: New-User-Header;
    display: flex;
    flex-direction: row;
    gap: 0px 15px;
    align-items: center;
    color: #4295AF;
    font-weight: 600;
    font-size: 15px;
    height: fit-content;
}

/* Email Address */

.New-User-Email {
    grid-area: New-User-Email;
    padding: 0px 40px
}

/* User Name */

.New-User-Name {
    grid-area: New-User-Name;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 0px 40px;
    width: 100%;
}

.New-User-Name div {
    width: 100%;
}

/* Buttons */

.New-User-Buttons {
    grid-area: New-User-Buttons;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 15px 0px 10px 40px;
}