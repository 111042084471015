/* =============================== */
/*      Shared Classes             */
/* =============================== */

.ProjectSetting-Form {
    display: grid;
    row-gap: 20px;
}

.ProjectSetting-Form-Row {
    border-top: 1px #D8D8D8 solid;
    padding: 5px;
}

.ProjectSetting-Form-Row label {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.ProjectSetting-Form-Row select {
    margin-top: 10px;
}

.ProjectSetting-Form-Row input {
    padding: 20px;
}

.ProjectSetting-Form-Row textarea {
    padding: 20px;
}

.ProjectSetting-Form-Radio {
    display: flex;
    align-items: center;
}

.ProjectSetting-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.ProjectSetting-Header h5 {
    margin: 0px;
}


/* =============================== */
/*        Overview Classes         */
/* =============================== */

/* Mark project as completed modal */
.ProjectSetting-Modal {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 2;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid var(--teal);
}

/* =============================== */
/*    Organisations Classes        */
/* =============================== */

.ProjectSetting-Search-Container {
    display: grid;
    grid-template-columns: 1fr 100px;
    margin-top: 20px;
}

.ProjectSetting-Search {
    display: grid;
    grid-template-columns: 30px 1fr;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    gap: 10px;
    background-color: white;
    align-items: center;
    margin-right: 20px;
}

.ProjectSetting-Search img {
    margin-left: 10px;
    padding: 2px;
}

.ProjectSetting-Search input {
    border: none;
    height: 36px;
    width: 100%;
    font-size: var(--body);
    color: #555555;
}

.ProjectSetting-Search input:active {
    outline: none;
}

.ProjectSetting-Search input:focus {
    outline: none;
}

.ProjectSetting-Search-List {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin-right: 20px;
    border-left: 1px solid #D8D8D8;
    border-right: 1px solid #D8D8D8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.ProjectSetting-Search-List label {
    padding: 10px 50px;
    border-bottom: 1px solid #D8D8D8;
    color: #555555;
}

.ProjectSetting-Search-List label:hover {
    cursor: pointer;
    background-color: var(--teal);
    color: white;
}

.ProjectSetting-Search-List label:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid #D8D8D8;
}

.ProjectSetting-Search-List label:last-child:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom: 1px solid #D8D8D8;
}

.ProjectSetting-Table {
    background-color: white;
    margin-top: 40px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

/* Project Owners Classes */
.ProjectSetting-Form-ProjectOwners {
    display: grid;
    grid-template-columns: 500px;
    align-items: center;
    row-gap: 10px;
}

/* ---------------------------------------------- */
/*  New User Form                                 */
/* ---------------------------------------------- */

.New-User-Form {
    display: grid;
    gap: 5px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "New-User-Header"
        "New-User-Email"
        "New-User-Name"
        "New-User-Buttons";
    background-color: rgba(132, 193, 212, 0.19);
    padding: 2%;
    border-radius: 5px;
    margin: 5px 0px 30px 0px;
    height: 300px;
}

.New-User-Form p {
    font-size: 13px;
    color: #454040;
    margin: 6px 0px;
}

.New-User-Form input {
    font-size: 13px;
    width: 100%;
}

/* Header */

.New-User-Header {
    grid-area: New-User-Header;
    display: flex;
    flex-direction: row;
    gap: 0px 15px;
    align-items: center;
    color: #4295AF;
    font-weight: 600;
    font-size: 15px;
    height: fit-content;
}

/* Email Address */

.New-User-Email {
    grid-area: New-User-Email;
    padding: 0px 40px
}

/* User Name */

.New-User-Name {
    grid-area: New-User-Name;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 0px 40px;
    width: 100%;
}

.New-User-Name div {
    width: 100%;
}

/* Buttons */

.New-User-Buttons {
    grid-area: New-User-Buttons;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 15px 0px 10px 40px;
}

/* OrganisationTableRow Classes */

.ProjectSetting-Organisation-Header {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    border-bottom: 1px solid #D8D8D8;
    color: #494949;
    align-items: center;
}

/* Edit Organisation > Expanded Pane */

.ProjectSetting-Table-Open {
    padding: 20px 40px;
    border-bottom: 1px solid #D8D8D8;
    background-color: #FEFEFE;
}

/* Edit Organisation Form > Fields */

.ProjectSetting-Org-Form-Row {
    display: flex;
    flex-direction: column;
    gap: 0px 0px;
}

.ProjectSetting-Org-Form-Row b {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.75px;
    padding: 15px 0px;
}

.ProjectSetting-Org-Form-Row select {
    margin-top: 10px;
}

.ProjectSetting-Org-Form-Row input {
    padding: 20px;
}

.ProjectSetting-Org-Form-Row textarea {
    padding: 20px;
}

.ProjectSettings-Modal-Worker {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 2;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #D85C5C;
}

.Organisation-Field-Container {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        ". .";
    padding: 20px 0px 0px 0px;
    align-items: start;
}

.Organisation-Field-Container label {
    font-weight: 500;
    padding: 0px;
}

/* ---------------------------------------------- */
/*  New User Form                                 */
/* ---------------------------------------------- */

.New-User-Form {
    display: grid;
    gap: 5px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "New-User-Header"
        "New-User-Email"
        "New-User-Name"
        "New-User-Buttons";
    background-color: rgba(132, 193, 212, 0.19);
    padding: 25px;
    border-radius: 5px;
    margin: 0px;
    height: 300px;
}

.New-User-Form p {
    font-size: 13px;
    color: #454040;
    margin: 6px 0px;
}

.New-User-Form input {
    font-size: 13px;
    width: 100%;
}

/* Header */

.New-User-Header {
    grid-area: New-User-Header;
    display: flex;
    flex-direction: row;
    gap: 0px 15px;
    align-items: center;
    color: #4295AF;
    font-weight: 600;
    font-size: 15px;
    height: fit-content;
}

/* Email Address */

.New-User-Email {
    grid-area: New-User-Email;
    padding: 0px 40px
}

/* User Name */

.New-User-Name {
    grid-area: New-User-Name;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 0px 40px;
    width: 100%;
}

.New-User-Name div {
    width: 100%;
}

/* Buttons */

.New-User-Buttons {
    grid-area: New-User-Buttons;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 15px 0px 10px 40px;
}