/* =================================================== */
/*          Send for Approval Styling                  */
/* =================================================== */

.Send-Approval-Application-Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 10;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #E4BA25;
}

/* =================================================== */
/*          Request More Info Styling                  */
/* =================================================== */

.Request-More-Info-Application-Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 10;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #E47525;
}

/* =================================================== */
/*          Reject Application Styling                 */
/* =================================================== */

.Reject-Application-Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 10;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #D85C5C;
}

/* =================================================== */
/*          Approve Application Styling                */
/* =================================================== */

.Approve-Application-Modal-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 10;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #61B280;
}

/* =================================================== */
/*          Modal Styling                              */
/* =================================================== */

.Application-Approvals-Modal-Header {
    display: flex;
    flex-direction: row;
    font-weight: 400;
}

.Application-Approvals-Modal-Header h5 {
    padding: 20px 0 0 20px;
}

.Application-Approvals-Modal-Header img {
    width: 50px;
}

.Application-Approvals-Modal-Body {
    padding: 20px 40px 40px 40px;
    font-size: 14px;
}

.Application-Approvals-Modal-Body input {
    width: 200px;
    height: 30px;
    border: 1px solid #4B4B4B 5px;
}

.Application-Approvals-Modal-Body label {
    font-size: 10px;
    font-weight: 600;
    padding: 15px 0 5px 0;
}


.Application-Approvals-Modal-SendForApproval-Confirm-Button {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    background-color: #E4BA25;
    border: none;
    color: white;
    margin: 10px;
}

.Application-Approvals-Modal-RequestMoreInfo-Confirm-Button {
    width: 120px;
    height: 35px;
    border-radius: 5px;
    border: none;
    color: white;
    margin: 10px;
}

.Application-Approvals-Modal-RequestMoreInfo-Confirm-Button.valid {
    background-color: #E47525;
}

.Application-Approvals-Modal-RequestMoreInfo-Confirm-Button.invalid {
    background-color: #C0C0C0;
}

.Application-Approvals-Modal-RejectApplication-Confirm-Button {
    width: 140px;
    height: 35px;
    border-radius: 5px;
    background-color: #D85C5C;
    border: none;
    color: white;
    margin: 10px;
}

.Application-Approvals-Modal-ApproveApplication-Confirm-Button {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    border: none;
    color: white;
    margin: 10px;
}

.Application-Approvals-Modal-ApproveApplication-Confirm-Button.valid {
    background-color: #61B280;
}

.Application-Approvals-Modal-ApproveApplication-Confirm-Button.invalid {
    background-color: #C0C0C0;
}

.Application-Approvals-Modal-Cancel-Button {
    width: 100px;
    height: 35px;
    border-radius: 5px;
    background-color: none;
    border: 1px solid #4B4B4B;
    color: #4B4B4B;
    margin: 10px;
}