/* ======================================= */
/*   Comments Thread CSS                   */
/* ======================================= */

.Comments-Thread-Container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Comment-Container {
    display: flex;
    grid-template-rows: 90px 1fr;
    grid-template-areas:
        'Initials' 'Body';
    gap: 0px 20px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 35px;
    width: 100%;
}

.Comment-Box-Initials {
    grid-area: 'Initials';
    border-radius: 50%;
    border: 1px solid var(--teal);
    color: var(--teal);
    padding: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    height: 45px;
}

.Comment-Box-Body {
    grid-area: 'Body';
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    color: #545454;
}

.Comment-Box-Body p {
    font-weight: 500;
    color: #555;
    margin: 0px;
}

.Comment-Box-Body label {
    font-size: 11px;
    color: #737373;
    margin-bottom: 10px;
}

.Comment-Box-File-Link {
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid lightgray;
    border-left: 6px solid #6EB7CE;
    padding: 8px 15px;
    margin: 10px 0px;
    width: 100%;
}