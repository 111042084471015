.Breadcrumbs-Container {
    font-size: var(--fontsize-body);
    font-weight: normal;
    color: black;
    padding: 0px 0px 5px 5px;
}

.Breadcrumbs-Link {
    color: #0C6CDE;
    cursor: pointer;
    font-size: var(--fontsize-button);
    font-weight: medium;
    width: fit-content;
}