/* CSS Rules */

/* Tailwind directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Web Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

/* ------------------------------------------------------ */
/** CSS Variables                                        **/
/* ------------------------------------------------------ */

:root {
    /* Font Family */
    --font: 'Inter';

    /* Font Sizes */
    --heading: 1.5rem;
    --subheading: 1.25rem;
    --body: 1rem;

    /* Colours */
    --teal: #84C1D4;
    --fadedblue: #84C1D4;
    --lightgrey: #E0E0E0;
    --darkgrey: #212431;
    --almostblack: #383838;
    --disabledgrey: #C0C0C0;
    --darkred: #DE0000;

}

/* Rule - Images should never be rendered at a size wider than their containing element using max-inline-size */
img {
    max-inline-size: 100%;
    block-size: auto;
}

/* ------------------------------------------------------ */
/** Text standard                                        **/
/*  Purpose: Standards for titles, subtitles and body     */
/* ------------------------------------------------------ */

/* Fonts */
* {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

/* Heading */
h1 {
    font-size: var(--heading);
}

/* Subheading */
h2 {
    font-size: var(--subheading);
}


/* Body */
div {
    font-size: var(--body);
}

.Subheading {
    font-size: var(--subheading);
    font-weight: 500;
}

/* ============================================== */
/*  Default Page Container                        */
/* ============================================== */

.PageComponent-Container {
    display: grid;
    grid-template-areas:
        'Header'
        'Body';
    padding: 3% 5%;
}

.PageComponent-Header {
    grid-area: Header;
}

.PageComponent-Body {
    grid-area: Body;
}

.PageComponent-AccessDenied {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1%;
    line-height: 2;
}

.PageComponent-AccessDenied img {
    max-width: 150px;
    margin-bottom: 20px;
}

.PageComponent-Errors-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1%;
}

.PageComponent-Errors-Container p {
    line-height: 1.7;
}

.Page-Body-Container {
    background-color: #FAFAFA;
    margin: 2% 0%;
    padding: 2% 4%;
    border-radius: 5px;
    font-size: 0.875rem;
}

/* ------------------------------------------------------ */
/*  Scroll Bars                                           */
/* ------------------------------------------------------ */

/* bar width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border: 1px solid #D8D8D8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* ------------------------------------------------------ */
/** Primary Button (Black Fill & White Text)             **/
/*  Purpose: Use this for a call to action button         */
/* ------------------------------------------------------ */

.Primary-Button {
    border: 1px solid var(--teal);
    background-color: var(--teal);
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 5px 0px 5px 0px;
    font-weight: normal;
    box-shadow: 0px 1px 1px rgba(135, 135, 135, 0.5);
    font-size: var(--body);
    min-width: 100px;
}

.Primary-Button:disabled {
    background-color: var(--disabledgrey);
    border: 1px solid var(--disabledgrey);
    color: white;
}

.Primary-Button:disabled:hover {
    transform: none;
    cursor: not-allowed;
}

.Primary-Button:hover {
    transform: scale(1.03);
    cursor: pointer;
}

/* ------------------------------------------------------ */
/** Secondary Button (White Fill & Black Text)           **/
/*  Purpose: Use this secondary action, e.g. cancel       */
/* ------------------------------------------------------ */

.Secondary-Button {
    border: 1px solid var(--teal);
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    color: var(--teal);
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    margin: 5px 10px 5px 0px;
    cursor: pointer;
    font-size: var(--body);
    min-width: 100px;
}

.Secondary-Button-Active {
    border: 1px solid var(--teal);
    background-color: var(--teal);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(106, 106, 106, 0.5);
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 500;
    margin: 5px 10px 5px 0px;
    cursor: pointer;
    font-size: var(--body);
    min-width: 100px;
}

.Secondary-Button-Active:disabled {
    cursor: text;
}

.Secondary-Button:disabled {
    cursor: text;
    color: var(--disabledgrey);
    border: 1px solid var(--disabledgrey);
}

.Secondary-Button:hover {
    transform: scale(1.03);
    cursor: pointer;
}

/* ------------------------------------------------------------ */
/** Link Button (Grey Fill & White Text)                       **/
/*  Purpose: Use this for any page link buttons                 */
/* ------------------------------------------------------------ */

.Link-Button {
    border: 2px solid #3C3C3C;
    background-color: #3C3C3C;
    color: white;
    border-radius: 30px;
    padding: 15px;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
}

.Link-Button:hover {
    background-color: #535353;
    color: white;
}

/* ------------------------------------------------------ */
/*  Submit Button (Primary Button Style)                  */
/*  Purpose: Use this in forms for the submit button      */
/* ------------------------------------------------------ */

.Submit-Button {
    border: 2.5px solid black;
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 15px 10px 10px 0px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-size: var(--body);
}

.Submit-Button:hover {
    transform: scale(1.03);
}

/* ------------------------------------------------------ */
/*  Button Group                                          */
/*  Purpose: Combination of submit and cancel button      */
/* ------------------------------------------------------ */

.Button-Group {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

/* ------------------------------------------------------ */
/*  Form Styling                                          */
/* ------------------------------------------------------ */

.Form {
    min-width: 250px;
    border: 0.5px solid #daddd7;
    color: #3A3A3A;
    text-align: left;
    border-radius: 3px;
    padding: 40px;
    font-weight: 600;
}

.Form-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: black;
    font-size: var(--heading);
}

.Form.Group {
    margin: 15px 10px;
    padding: 1.5em;
}

/* ----- Field Styling ----- */

.Label-Field-Title {
    margin: 25px 0px 0px 0px;
    font-size: var(--body);
}

.Label-Field-Value {
    font-size: var(--body);
    color: #929292;
}


/* ------------------------------------------------------ */
/*  Input Field (Text)                                    */
/* ------------------------------------------------------ */

.Input-Field-Text {
    font-size: var(--body);
    height: 25px;
    width: 450px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    background-color: white;
    color: black;
}

.Input-Field-Text:disabled {
    color: #636363;
}

.Input-Field-Text:active {
    outline: 1px #35394e7e solid;
}

.Input-Field-Text:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-Text:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-Text-Disabled {
    font-size: var(--body);
    height: 25px;
    width: 450px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    background-color: #EFEFEF4D;
    color: #929292;
}

.Input-Field-Text-Disabled:active {
    outline: none;
}

.Input-Field-Text-Disabled:focus {
    outline: none;
}

.Input-Field-Text-Error {
    height: 25px;
    width: 450px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    box-sizing: border-box;
}

.Input-Field-Text-Error:active {
    outline: 1px var(--darkred) solid;
}

.Input-Field-Text-Error:focus {
    outline: 1px var(--darkred) solid;
}

.Input-Field-Text-Error {
    height: 25px;
    width: 450px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    box-sizing: border-box;
}

.Input-Field-Text-Error-Message {
    font-size: var(--body);
    color: var(--darkred);
}

.Input-Field-TextArea {
    font-size: var(--body);
    height: 200px;
    width: 100%;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    color: black;
}

.Input-Field-TextArea:disabled {
    background-color: #EFEFEF4D;
    color: #929292;
}

.Input-Field-TextArea:disabled:active {
    outline: none;
}

.Input-Field-TextArea:active {
    outline: 1px #35394e7e solid;
}

.Input-Field-TextArea:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-TextArea:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-TextArea-Error {
    font-size: var(--body);
    height: 200px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    box-sizing: border-box;
}

.Input-Field-TextArea-Error:active {
    outline: 1px solid var(--darkred);
}

.Input-Field-TextArea-Error:focus {
    outline: 1px solid var(--darkred);
}

.Label-List-Item {
    display: flex;
    align-items: center;
    font-size: var(--body);
    border-radius: 5px;
    border: 1px #E3F6F5 solid;
    background-color: #E3F6F5;
    height: 25px;
    width: 100%;
    margin: 5px;
    padding: 20px;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}

.Input-Field-Select {
    font-size: var(--body);
    color: #212529;
    height: 42px;
    width: 150px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
}

.Input-Field-Select option {
    color: #212529;
}

.Input-Field-Select:active {
    outline: 1px #35394e7e solid;
}

.Input-Field-Select:disabled {
    outline: 1px solid #D8D8D8;
    background-color: #F7F7F7;
}

.Input-Field-Select:focus {
    outline: 1px #35394e7e solid;
}

.Input-Field-Select:invalid {
    outline: 2px #ff15007e solid;
}

.Input-Field-Select-Error {
    font-size: var(--body);
    color: #929292;
    height: 42px;
    width: 150px;
    border-radius: 5px;
    border: 1px solid var(--darkred);
    padding: 10px;
}

.Input-Field-Select-Error:active {
    outline: 1px solid var(--darkred);
}

.Input-Field-Select-Error:focus {
    outline: 1px solid var(--darkred);
}

.Input-Field-Radio {
    height: 25px;
    width: 25px;
    margin: 10px;
    cursor: pointer;
}

.Input-Icon {
    width: 35px;
    cursor: pointer;
}

.Input-Icon-Disabled {
    width: 35px;
    cursor: not-allowed;
}

/* ------------------------------------------------ */
/*  Table Styling                                   */
/* ------------------------------------------------ */

.Table-Container {
    table-layout: fixed;
    width: 100%;
    text-align: left;
    font-size: 15px;
    border-bottom: 0.5px solid lightgray;
    background-color: white;
}

table.Table-Container th {
    padding: 20px 15px 20px 50px;
    font-weight: 500;
    border-bottom: 1px solid lightgray;
}

table.Table-Container td {
    padding: 8px 15px 8px 50px;
}

/* ---------------------------------------------- */
/*  Modal Background                              */
/* ---------------------------------------------- */

.Modal-Background {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.Action-Modal-Container {
    display: grid;
    grid-template-columns: 0.15fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 10px;
    grid-auto-flow: row;
    grid-template-areas:
        "Action-Modal-Icon Action-Modal-Body";
}

.Action-Modal-Icon {
    grid-area: Action-Modal-Icon;
    justify-self: center;
    align-self: flex-start;
    padding-top: 5px;
    padding-left: 5px;
}

.Action-Modal-Body {
    grid-area: Action-Modal-Body;
    justify-content: flex-start;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 5px 0px;
    line-height: 1.8;
    margin-right: 35px;
}

.Action-Modal-Body h4 {
    margin: 15px 0px;
}

.Action-Modal-Body-Form {
    display: flex;
    flex-direction: column;
}

.Action-Modal-Body-Form input {
    font-size: 15px;
    margin: 0px;
    border: 1px solid #aaaaaa;
    border-radius: 5px;
    padding: 8px 15px;
}

.Action-Modal-Body label {
    color: #696767;
    margin-bottom: 10px;
}

.Action-Modal-Input-Field {
    padding: 8px 18px;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: var(--body);
}

.Action-Modal-Input-Field:disabled {
    background-color: #f3f3f3;
}

.Action-Modal-Input-Field-Error {
    padding: 5px 15px;
    border: 1px solid var(--darkred);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: var(--body);
}

.Action-Modal-Input-Error-Message {
    color: var(--darkred);
    font-weight: 500;
    font-size: 0.85rem;
    margin: 8px 0px 15px 0px;
}

.Action-Modal-Select {
    font-size: var(--body);
    color: #212529;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    padding: 10px 15px;
}

.Action-Modal-Select option {
    color: #212529;
}

.Action-Modal-Input-Error-Message-Hidden {
    display: none;
}

.Action-Modal-Button-Container {
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    margin: 0px;
}

.Action-Modal-Button-Container button:disabled {
    background-color: var(--disabledgrey);
}

/* ---------------------------------------------- */
/*  Divider                                       */
/* ---------------------------------------------- */

.Solid-Divider {
    border-top: 1px solid #bbb;
    border-radius: 3px;
    margin: 2% 0px;
}

/* ------------------------------------------------ */
/*  Radio Buttons                                   */
/* ------------------------------------------------ */

.Radio-Buttons-Container {
    display: block;
    position: relative;
    padding: 0px 5px 0px 35px;
    margin-left: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.Radio-Buttons-Container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.Radio-Checkmark {
    position: absolute;
    top: 0;
    left: 0px;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1.5px solid #9F9C9C;
    border-radius: 50%;
}

/* Error State for a Radio Button */
.Radio-Checkmark-Error {
    position: absolute;
    top: 0;
    left: 0px;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1.5px solid var(--darkred);
    border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.Radio-Checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.Radio-Buttons-Container input:checked~.Radio-Checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.Radio-Buttons-Container .Radio-Checkmark:after {
    top: 4px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--teal);
}