/* ------------------------------------------------------------ */
/** Navigation Header Styling                                             **/
/* ------------------------------------------------------------ */

.NavHeader-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--darkgrey);
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 100;
    padding: 12px 25px;
    height: 85px;
    min-width: 710px;
}

/* ------------------------------------------- */
/** Nav Header Left Pane > Title & Logo       **/
/* ------------------------------------------- */

.NavHeader-LeftPane {
    display: flex;
    flex-direction: row;
    gap: 10px 10px;
    align-items: center;
}

.NavHeader-LeftPane h4 {
    font-weight: 400;
    font-size: 1.45rem;
    margin: 10px 0px;
}

.NavHeader-LeftPane img {
    width: 83px;
    height: 60px;
    margin: 0px 15px;
    cursor: pointer;
}

.NavHeader-LeftPane img:hover {
    transform: scale(1.05);
}

/* ------------------------------------------- */
/** Nav Header Right Pane > Title & Logo      **/
/* ------------------------------------------- */

.NavHeader-RightPane {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* ---------------------------------- */
/*  Project Selector Dropdown          */
/* -----------------------------------*/

.Project-Selector-Dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--darkgrey);
    color: white;
    border: 1.5px solid #575C6F;
    font-family: var(--body);
    font-size: var(--fontsize-body);
    text-align: left;
    padding: 10px 20px;
    margin: 0px 20px;
    border-radius: 5px;
    min-width: 300px;
}

.Project-Selector-Dropdown:hover {
    background-color: #3d404d;
}

.Project-Selector-Dropdown p {
    margin: 0px 15px;
}

/* ------------------------------------------- */
/*  Settings                                   */
/* ------------------------------------------- */

.Settings-Icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.Settings-Icon:hover~.Settings-Dropdown {
    visibility: visible;
}

.Settings-Dropdown {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 85px;
    right: 0px;
    background-color: white;
    color: var(--almostblack);
    border: none;
    border-radius: 1px;
    box-shadow: 5px 10px 35px 3px rgba(81, 81, 81, 0.35);
    visibility: hidden;
    transition-duration: 0.3s;
}

.Settings-Dropdown:hover {
    visibility: visible;
}

.Settings-Dropdown-Link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 25px;
    font-size: 1rem;
    border-bottom: 1px solid var(--lightgrey);
    width: 100%;
    color: black;
    text-decoration: none;
}

.Settings-Dropdown-Link:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
    color: black;
}

.Settings-Link-Icon {
    width: 31px;
    height: 31px;
}