.PageHeader-Container {
    display: grid;
    grid-template-columns: 65px 1fr;
    grid-template-rows: 1fr;
    gap: 0px 15px;
    align-items: center;
    text-align: left;
    grid-template-areas:
        "PageHeader-Icon PageHeader-Title";
}

.PageHeader-Icon {
    grid-area: PageHeader-Icon;
    justify-self: center;
}

.PageHeader-Title {
    grid-area: PageHeader-Title;
    font-weight: 500;
    font-size: 1.45rem;
    display: flex;
    justify-content: space-between;
}