/* ---------------------------------------------- */
/*  Project Selector Container                    */
/* ---------------------------------------------- */

.Project-Selector-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 25px;
    border-radius: 5px;
    z-index: 2;
    position: absolute;
    width: 50vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 60%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

/* ---------------------------------------------- */
/*  Project Selector Header                       */
/* ---------------------------------------------- */

.Project-Selector-Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5px;
    color: black;
}

/* ---------------------------------------------- */
/*  Project Selector Table                        */
/* ---------------------------------------------- */

.Project-Selector-Table-Container {
    overflow-x: hidden;
    overflow-y: auto;
    color: black;
}

.Project-Selector-Table-Container::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

.Project-Selector-Table-Container::-webkit-scrollbar-track {
    background: rgba(195, 195, 195);
}

.Project-Selector-Table-Container::-webkit-scrollbar-thumb {
    background-color: rgba(121, 121, 121, 0.411);
    border-radius: 30px;
    border: none;
}

.Project-Selector-Table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    text-align: left;
    font-size: 15px;
    border-bottom: 0.5px solid lightgray;
}

table.Project-Selector-Table th {
    padding: 10px 15px;
    font-weight: 500;
    border-bottom: 0.5px solid lightgray;
}

table.Project-Selector-Table td {
    padding: 8px 15px;
}

table.Project-Selector-Table tr {
    cursor: pointer;
}

.Project-Selector-Resource-Row {
    background-color: white;
}

.Project-Selector-Resource-Row:hover {
    background-color: #F0F5FE;
}

.Project-Selector-Resource-Row-Active {
    background-color: #F0F5FE;
}

/* Project Selector Description */

.Project-Selector-Description {
    overflow: hidden;
    line-height: 1.6;
    text-overflow: ellipsis;
    display: -webkit-box; /* fallback */
    height: 2rem; /* fallback */
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

/* ---------------------------------------------- */
/*  Project Selector Cancel Button                */
/* ---------------------------------------------- */

.Project-Selector-Cancel {
    text-align: right;
}

.Project-Selector-Cancel-Button {
    background-color: white;
    border: none;
    font-weight: 500;
    margin: 10px 20px 0px 20px;
    color: black;
}

.Project-Selector-Cancel-Button:hover {
    transform: scale(1.03);
}