/* ------------------------------------------------------ */
/*  Table Rows                                            */
/* ------------------------------------------------------ */

.GlobalAdmins-User-Row {
    display: grid;
    grid-template-columns: 80px 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "GlobalAdmins-User-Icon GlobalAdmins-User-Name"
        "GlobalAdmins-User-Icon GlobalAdmins-User-Email";
    margin: 10px 0px;
}

.GlobalAdmins-User-Icon {
    grid-area: GlobalAdmins-User-Icon;
}

.GlobalAdmins-User-Name {
    grid-area: GlobalAdmins-User-Name;
}

.GlobalAdmins-User-Email {
    grid-area: GlobalAdmins-User-Email;
}

/* =============================== */
/*        Organisations Classes    */
/* =============================== */

.AppSettings-Form {
    display: grid;
    row-gap: 20px;
}

.AppSettings-Form-Row {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.AppSettings-Form-Row label {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.AppSettings-Form-Row select {
    margin-top: 10px;
}

.AppSettings-Form-Row input {
    padding: 20px;
}

.AppSettings-Form-Row textarea {
    padding: 20px;
}

.AppSettings-Search-Container {
    display: grid;
}

.AppSettings-Search {
    display: grid;
    grid-template-columns: 30px 1fr;
    border-radius: 5px;
    border: 1px solid #D8D8D8;
    gap: 20px;
    background-color: white;
    align-items: center;
    padding: 5px;
}

.AppSettings-Search img {
    margin-left: 10px;
    padding: 2px;
}

.AppSettings-Search input {
    border: none;
    height: 36px;
    font-size: var(--body);
}

.AppSettings-Search input:active {
    outline: none;
}

.AppSettings-Search input:focus {
    outline: none;
}

/* Org Table Styling */
.AppSettings-Table {
    background-color: white;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
}

.AppSettings-Table-Closed {
    display: flex;
    justify-content: space-between;
    padding: 20px 40px;
    border-bottom: 1px solid #D8D8D8;
    align-items: center;
}

.AppSettings-Table-Open {
    padding: 20px 40px;
    border-bottom: 1px solid #D8D8D8;
    background-color: #FEFEFE;
}

/* Project Owners Classes */
.AppSettings-Form-Users {
    display: grid;
    grid-template-columns: 500px;
    align-items: center;
    row-gap: 10px;
}

/* Project List Classes */
.AppSettings-Projects-Label {
    display: grid;
    grid-template-columns: 445px 52px;
    gap: 15px;
    margin: 0px;
    border-radius: 10px;
    align-items: center;
}

/* Delete Org Button Classes */
.AppSettings-DeleteOrg-Button {
    height: 36px;
    cursor: pointer;
    margin-top: 5px;
}

.AppSettings-DeleteOrg-Button:hover {
    transform: scale(1.03);
}

/* Remove Worker modal */
.AppSettings-Modal-Worker {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 35px 25px;
    border-radius: 5px;
    z-index: 2;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid #D85C5C;
}


/* =============================== */
/*        Audit Logs Classes       */
/* =============================== */

.AppSettings-AuditLogs-Header img {
    width: 15px;
    margin: 0px 5px;
}

/* -------------------------------- */
/*   Input Fields                   */
/* -------------------------------- */

.Input-Field-MultiSelect {
    font-size: var(--body);
    width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
    font-weight: normal;
}

.Input-Field-MultiSelect option {
    margin: 5px 0px;
    padding: 5px;

}

.Input-Field-MultiSelect option:active {
    background-color: #0972D3;
    color: white;
}

.Input-Field-MultiSelect option:checked {
    background-color: #0972D3;
    color: white;
}

.Input-Field-MultiSelect option:disabled {
    background-color: #E9EBED;
    color: #9F9C9C;
}

/* -------------------------------- */
/*   Searchable Select              */
/* -------------------------------- */

.Searchable-Select {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    font-size: var(--body);
    width: 100%;
    height: 150px;
    border-radius: 5px;
    border: 1px #D8D8D8 solid;
    padding: 10px;
    font-weight: normal;
    text-align: left;
    overflow-y: auto;
}

.Searchable-Select button {
    text-align: left;
    padding: 0px 8px;
    cursor: default;
}

/* ---------------------------------------------- */
/*  Invite User Modal Container                   */
/* ---------------------------------------------- */

.Invite-User-Container {
    display: flex;
    flex-direction: column;
    gap: 15px 0px;
    padding: 25px;
    border-radius: 5px;
    z-index: 10;
    position: absolute;
    width: 45vw;
    min-width: 480px;
    min-height: 20%;
    max-height: 70%;
    top: 17.5%;
    left: calc(50% + 250px);
    transform: translate(calc(-50% - 250px), 0);
    background-color: #FFFFFF;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
    border-top: 15px solid var(--teal);
    overflow-y: auto;
}

/* Email Address Input Field */
.Invite-User-Email-Container {
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    box-sizing: border-box;
}

.Invite-User-Email-Container input {
    width: 90%;
}

.Invite-User-Email-Container img {
    cursor: pointer;
}

/* Users List  */

.Invite-Users-List {
    margin: 25px 0px;
    max-height: 115px;
    overflow-y: scroll;
}

.Invite-Users-List::-webkit-scrollbar {
    width: 10px;
}

.Invite-Users-List::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 5px;
}

.Invite-Users-List::-webkit-scrollbar-thumb {
    background: var(--teal);
    border-radius: 5px;
}

/* User Labels */

.Invite-User-Label-Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0px 10px;
}

.Invite-User-Label-Container div {
    background: rgba(132, 193, 212, 0.19);
    padding: 10px 20px;
    color: #252525;
    text-decoration: none;
    font-weight: normal;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 85%;
}

.Invite-User-Label-Container img {
    cursor: pointer;
}

/* ---------------------------------------------- */
/*  New User Form                                 */
/* ---------------------------------------------- */

.New-User-Form {
    display: grid;
    gap: 5px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "New-User-Header"
        "New-User-Email"
        "New-User-Name"
        "New-User-Buttons";
    background-color: rgba(132, 193, 212, 0.19);
    padding: 25px;
    border-radius: 5px;
    margin: 0px;
    height: 300px;
}

.New-User-Form p {
    font-size: 13px;
    color: #454040;
    margin: 6px 0px;
}

.New-User-Form input {
    font-size: 13px;
    width: 100%;
}


/* Header */

.New-User-Header {
    grid-area: New-User-Header;
    display: flex;
    flex-direction: row;
    gap: 0px 15px;
    align-items: center;
    color: #4295AF;
    font-weight: 600;
    font-size: 15px;
    height: fit-content;
}

/* Email Address */

.New-User-Email {
    grid-area: New-User-Email;
    padding: 0px 40px
}

/* User Name */

.New-User-Name {
    grid-area: New-User-Name;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 0px 40px;
    width: 100%;
}

.New-User-Name div {
    width: 100%;
}

/* Buttons */

.New-User-Buttons {
    grid-area: New-User-Buttons;
    display: flex;
    flex-direction: row;
    gap: 0px 10px;
    padding: 15px 0px 10px 40px;
}